import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import settings from "./settings";
import { _FP, $api } from "bh-mod";

Vue.use(Vuex);
export default new Vuex.Store({
	modules: {
		user,
		settings,
	},
	state: {
		loading: false,
		fingerPrint: _FP,
		callInstances: false,
		instances: [],
		invitations: [],
		instance: null,
		hives: [],
		cats: [],
		theApp: {},
		editDrawer: {
			show: false,
			instance: {},
		},
		childrenList: {
			instance: "",
			children: [],
		},
		marketPlace: {
			show: false,
			item: {},
		},
		allSettings: {},
		fileStructure: [],
		filterDrawer: {
			visible: false,
			filter: {
				showDelete: false,
				showLatestOnly: false,
				productType: ['any'],
				status: ['any'],
				timeFrame: [null, null],
				price: [0, 0]
			}
		},
	},
	mutations: {
		LOAD(state, data = false) {
			state.loading = data;
		},
		SET_INVITATIONS(state, data) {
			state.invitations = data;
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		CREATE_UPDATE: (state, data) => {
			state.instances.unshift(data);
		},
		SET_CHILDRENLIST: (state, data) => {
			state.childrenList.instance = data.id;
			state.childrenList.children = data.children;
		},
		SET_SETTINGS: (state, data) => {
			state.allSettings = data;
		},
		OPEN_MARKETPLACE: (state, data) => {
			state.marketPlace.show = true;
			state.marketPlace.item = data;
		},
		CLOSE_MARKETPLACE: (state, data) => {
			state.marketPlace.show = false;
			state.marketPlace.item = data;
		},
		OPEN_EDIT_INSTANCE: (state, data) => {
			state.editDrawer.show = true;
			state.editDrawer.instance = data;
		},
		CLOSE_EDIT_INSTANCE: (state, data) => {
			if (data != "") {
				state.instances = state.instances.map((item) => {
					if (item.id == data.id) {
						let { role } = item;

						return { ...data, role };
					}
					return item;
				});
			}
			state.editDrawer.show = false;
			state.editDrawer.instance = {};
		},
		SET_INSTANCES: (state, data) => {
			data = data.map((x) => {
				let own = false;
				if (x.billingOwner === state.user.user.id) own = true;
				return {
					...x,
					own,
				};
			});
			state.instances = data;
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data;
			state.cats = data.activeApps;
		},
		CLEAR_INSTANCE: (state, data) => {
			state.instance = {};
		},
		OPEN_FILTER(state) {
			state.filterDrawer.visible = true;
		},
		CLOSE_FILTER(state) {
			state.filterDrawer.visible = false;
		},
		UPDATE_FILTER(state, data) {
			state.filterDrawer.filter = data;
		},
		RESET_DEFAULT_FILTER(state) {
			state.filterDrawer.filter = {
				showDelete: false,
				showLatestOnly: false,
				productType: ['any'],
				status: ['any'],
				timeFrame: [null, null],
				price: [0, 0]
			};
		},
	},
	actions: {
		getInstances: async ({ state, commit }) => {
			try {
				let { data } = await $api.get("/instances");
				if (Array.isArray(data) && data?.length) {
					let instances = data.map(({ instance, role }) => {
						let own = false;
						if (instance.billingOwner === state.user?.user?.id) own = true;
						return {
							...instance,
							role,
							own,
						};
					});
					commit("SET_INSTANCES", instances);
				}
			} catch (error) {
				console.error('Error occurred while fetching instances', error)
			}
		},
	},
	getters: {},
});
